import React, { useState, useEffect } from 'react';
import './ContributionRequests.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ContributionRequests = ({ userDetail }) => {
  const [cardData, setCardData] = useState([]);
  const [notification, setNotification] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const response = await axios.get('https://api.syncpro.cloud/user-contribution-requests', {
          params: { page, rowsPerPage },
          headers: {
            'Authorization': `Bearer ${userDetail.token}`
          }
        });
        setCardData(response.data.contribution_requests || []);
        setTotalCount(response.data.totalCount);
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };

    fetchCardData();
  }, [userDetail.token, page, rowsPerPage]);

  const handleCreateProject = () => {
    navigate('/create-project');
  };

  const handleAction = async (_id, status) => {
    try {
      const response = await axios.post(
        'https://api.syncpro.cloud/approve-reject-contribution-request',
        { _id: _id, status },
        {
          headers: {
            'Authorization': `Bearer ${userDetail.token}`
          }
        }
      );
      console.log(response.data);
      setCardData(cardData.map(card => 
        card._id === _id ? { ...card, current_status: status } : card
      ));
      setNotification(`Request ${status === 'approved' ? 'approved' : 'rejected'} successfully!`);
      setTimeout(() => setNotification(''), 3000); // Hide notification after 3 seconds
    } catch (error) {
      console.error(`Error ${status === 'approved' ? 'approving' : 'rejecting'} request:`, error);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to the first page
  };

  return (
    <div className="contribution_requests">
      {notification && <div className="notification">{notification}</div>}
      {cardData.length > 0 ? (
        cardData.map((card, index) => (
          <div className='contribution_requests_container' key={index}>
            <div className='contribution_requests_card'>
              <div className='contribution_requests_card_wrapper'>
                <div className='contribution_requests_card_title'>
                  <span>{card.project_name || 'Project Name'}</span>
                </div>
                <div className='contribution_requests_creator_info'>
                  <div className='contribution_requests_created_by'>
                    <div className='contribution_requests_created_by_text'>Created by :</div> {card.user_name}
                  </div>
                  <div className='contribution_requests_created_at'>
                    <div className='contribution_requests_created_at_text'>Created at :</div> {new Date(card.created_at).toLocaleString()}
                  </div>
                </div>
                <div className='contribution_requests_key_features_container'>
                  <div className='contribution_requests_key_feature'>
                    <div className='contribution_requests_key_feature_key'>CPU</div>
                    <div className='contribution_requests_key_feature_value'>{card.cpu_description}</div>
                  </div>
                  <div className='contribution_requests_key_feature'>
                    <div className='contribution_requests_key_feature_key'>GPU</div>
                    <div className='contribution_requests_key_feature_value'>{card.gpu_description}</div>
                  </div>
                  <div className='contribution_requests_key_feature_last'>
                    <div className='contribution_requests_key_feature_key'>RAM</div>
                    <div className='contribution_requests_key_feature_value'>{card.ram_description}</div>
                  </div>
                </div>
              </div>
              <div className='contribution_requests_description'>
                <ul>
                  <li>Cover Note: {card.cover_note}</li>
                  <li>Status: {card.current_status || 'Pending'}</li>
                </ul>
              </div>
              <div className='contribution_requests_key_feature_accept_reject'>
                {card.current_status === 'approved' || card.current_status === 'rejected' ? (
                  <div className={`contribution_requests_status ${card.current_status}`}>
                    {card.current_status === 'approved' ? 'Approved' : 'Rejected'}
                  </div>
                ) : (
                  <>
                    <div 
                      className='contribution_requests_key_feature_accept' 
                      onClick={() => handleAction(card._id, 'approved')}
                    >
                      Accept
                    </div>
                    <div 
                      className='contribution_requests_key_feature_reject' 
                      onClick={() => handleAction(card._id, 'rejected')}
                    >
                      Reject
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>No contribution requests found.</div>
      )}

      {/* <div className="pagination">
        <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
          Previous
        </button>
        <span>Page {page}</span>
        <button onClick={() => handlePageChange(page + 1)} disabled={page * rowsPerPage >= totalCount}>
          Next
        </button>
      </div> */}
      {/* <div className="rows-per-page">
        <label>Rows per page:</label>
        <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
        </select>
      </div> */}
    </div>
  );
};

export default ContributionRequests;
