import React, { useState } from 'react';
import './Auth.css';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';

const Auth = ({ initialType, setAuthPopupVisible, userDetail, setUserDetail }) => {
  const [type, setType] = useState(initialType);

  const toggleAuthType = () => {
    setType(type === 'SignUp' ? 'SignIn' : 'SignUp');
  };

  return (
    <div className="auth_popup">
      <div className="auth_popup_inner">
        {type === 'SignUp' ? (
          <SignUp setAuthPopupVisible={setAuthPopupVisible} toggleAuthType={toggleAuthType} userDetail ={userDetail} setUserDetail={setUserDetail}/>
        ) : (
          <SignIn setAuthPopupVisible={setAuthPopupVisible} toggleAuthType={toggleAuthType} userDetail={userDetail} setUserDetail={setUserDetail}/>
        )}
      </div>
    </div>
  );
};

export default Auth;
