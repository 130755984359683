import './CreateContrubutionRequests.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const CreateContributionRequests = ({ userDetail }) => {
  const [stage, setStage] = useState(1);
  const [formData, setFormData] = useState({
    cover_note: '',
    start_date: '',
    cpu_description: '',
    gpu_description: '',
    ram_description: '',
    training_data_format: '',
    training_data_size: '',
    fields_in_training_data: '',
    student_or_professional: '',
    job_title: '',
    organization_name: ''
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const project = location.state?.project;

  const handleIncrement = () => {
    if (stage < 4) {
      setStage(stage + 1);
    } else {
      handleSubmit();
    }
  };

  const handleDecrement = () => {
    if (stage > 1) {
      setStage(stage - 1);
    }

    if (stage === 1) {
      navigate('/');
    }
  };

  const handleStageClick = (stageNumber) => {
    setStage(stageNumber);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const currentDate = new Date().toISOString();
    
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.syncpro.cloud/create-contribution-request',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userDetail.token}`
      },
      data: {
        ...formData,
        request_creation_date: currentDate,
        project_id: project._id,
        user_email: userDetail.email, // Map user_email to email from userDetail
        user_name: userDetail.name,
        project: project
      }
    };
    
    setLoading(true);
    try {
      const response = await axios.request(config);
      console.log(response.data);
      setLoading(false);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000); // Hide success message after 3 seconds
      // Reset form data
      setFormData({
        cover_note: '',
        start_date: '',
        cpu_description: '',
        gpu_description: '',
        ram_description: '',
        training_data_format: '',
        training_data_size: '',
        fields_in_training_data: '',
        student_or_professional: '',
        job_title: '',
        organization_name: ''
      });
      setStage(1);
      // Redirect to homepage after 3 seconds
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const renderStageContent = () => {
    switch (stage) {
      case 1:
        return (
          <div className="section Project_details">
            <h2>Stage 1: Motivation behind the Contribution</h2>
            <div className="field">
              <label>Cover Note</label>
              <textarea name="cover_note" value={formData.cover_note} onChange={handleChange} placeholder="Enter cover note"></textarea>
            </div>
            <div className="field">
              <label>Start Date</label>
              <input type="date" name="start_date" value={formData.start_date} onChange={handleChange} />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="section Project_details">
            <h2>Stage 2: Hardware details </h2>
            <div className="field">
              <label>CPU</label>
              <textarea name="cpu_description" value={formData.cpu_description} onChange={handleChange} placeholder="Description about CPU"></textarea>
            </div>
            <div className="field">
              <label>GPU</label>
              <textarea name="gpu_description" value={formData.gpu_description} onChange={handleChange} placeholder="Description about GPU"></textarea>
            </div>
            <div className="field">
              <label>RAM</label>
              <textarea name="ram_description" value={formData.ram_description} onChange={handleChange} placeholder="Description about RAM"></textarea>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="section Project_details">
            <h2>Stage 3: Training Data</h2>
            <div className="field">
              <label>Training Data Format</label>
              <textarea name="training_data_format" value={formData.training_data_format} onChange={handleChange} placeholder="Enter training data format"></textarea>
            </div>
            <div className="field">
              <label>Training Data Size</label>
              <textarea name="training_data_size" value={formData.training_data_size} onChange={handleChange} placeholder="Eg - 3.6 GB"></textarea>
            </div>
            <div className="field">
              <label>Fields in Training Data</label>
              <textarea name="fields_in_training_data" value={formData.fields_in_training_data} onChange={handleChange} placeholder="Eg - Field1, Field2, Field3"></textarea>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="section Project_details">
            <h2>Stage 4: Contributor Details</h2>
            <div className="field">
              <label>Name</label>
              <input type="text" name="name" value={userDetail.name} disabled />
            </div>
            <div className="field">
              <label>Email</label>
              <input type="email" name="email" value={userDetail.email} disabled />
            </div>
            <div className="field">
              <label>Student or Professional</label>
              <input name="student_or_professional" value={formData.student_or_professional} onChange={handleChange} placeholder="Student or Professional"></input>
            </div>
            <div className="field">
              <label>Job title</label>
              <input name="job_title" value={formData.job_title} onChange={handleChange} placeholder="Eg - Software Engineer"></input>
            </div>
            <div className="field">
              <label>Organization Name</label>
              <input name="organization_name" value={formData.organization_name} onChange={handleChange} placeholder="Organization Name"></input>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='create_contribution_container_sidenav_wrapper'>
      {loading && <div className="loader">Loading...</div>}
      {success && <div className="success-popup">Contribution request successfully created!</div>}
      <div className='create_contribution_container_project_meta_info_container'>
        <div className='create_contribution_container_project_meta_info_container_title'>Sample Project</div>
        <div className='create_contribution_container_project_meta_info_container_description'>
          It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
        </div>
      </div>
      <div className='create_contribution_container_project_meta_info'>
        <div className='create_contribution_container_sidenav'>
          {Array.from({ length: 4 }, (_, idx) => ({ stage: idx + 1, name: `Stage ${idx + 1}` })).map((item, idx) => {
            return (
              <div
                key={idx}
                className='create_contribution_container_sidenav_stage'
                style={{ background: `${stage === item.stage ? '#006fff' : 'lightgray'}`, color: `${stage === item.stage ? '#fff' : '#000'}` }}
                onClick={() => handleStageClick(item.stage)}
              >
                <div className='create_contribution_container_sidenav_stage_name'>{`Stage - ${item.stage}`}</div>
                <div className='create_contribution_container_sidenav_stage_detail'>{item.name}</div>
              </div>
            );
          })}
        </div>
        <div className="create_contribution_container">
          <div className="create_contribution_header">
            <h1> Create Contribution Request</h1>
          </div>
          <div className="create_contribution_content">
            {renderStageContent()}
          </div>
          <div className="create_contribution_footer">
            <button onClick={handleDecrement}>{stage === 1 ? 'Cancel' : 'Back'} </button>
            <button onClick={handleSubmit} disabled={stage !== 4}>{stage === 4 ? 'Submit' : 'Skip'}</button>
            <button onClick={handleIncrement} disabled={stage === 4}>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateContributionRequests;
