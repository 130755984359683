import './ProjectCard.css';
import useravtar from '../../assets/account.png';
import heart from '../../assets/heart.png';
import heart_filled from '../../assets/heart_filled.png';
import share from '../../assets/arrow.png';
import Network_Optimization from '../../assets/network-optimization.png';
import { Link } from 'react-router-dom';


const ProjectCard = ({ item }) => {


  return (
    <div className="project_card">
      <Link to={`/project`} state={{ project: item }}>
      <div className='project_card_title'>{item.title}</div>
          </Link>
      <div className='project_card_start_date'>{item.start_date}</div>
      <img className='project_card_img' src={Network_Optimization} alt="Avatar" />
      <div className="container">
        Using federated learning to improve signal coverage prediction for mobile networks by aggregating data from multiple service providers' base stations without exchanging sensitive network information.
      </div>
      <div className='project_card_navigations'>
        <div className='project_card_navigations_1'>
          <div className='project_card_navigations_like'>
            <img className='project_card_navigations_like_heart' src={heart} />
          </div>
          <div className='project_card_navigations_share'>
            <img className='project_card_navigations_like_heart' src={share} />
          </div>
        </div>
          <Link to={`/project`} state={{ project: item }}>
          <div className='project_card_navigations_2_contributions'>
            Contribute
            </div>
          </Link>
      </div>
    </div>
  );
}

export default ProjectCard;
