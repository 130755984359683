import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './DropDown.css';

const Dropdown = ({ list }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [labelItem, setLabelItem] = useState(null);
  const [typeDropdown, setTypeDropdown] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const { label } = list[0];
    if (typeof label !== 'undefined') {
      checkType(false);
      setLabelItem(label);
    } else {
      checkType(true);
      setLabelItem(list[0]);
    }
  }, [list]);

  useEffect(() => {
    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const checkType = (value) => {
    setTypeDropdown(value);
  };

  const showDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.addEventListener("click", handleClickOutside);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      document.removeEventListener("click", handleClickOutside);
    }
  };

  const chooseItem = (value) => {
    if (labelItem !== value) {
      setLabelItem(value);
      setIsOpen(false); // Close the dropdown after selection
    }
  };

  const renderDataDropDown = (item, index) => {
    const { value, label } = typeDropdown ? { value: index, label: item } : item;
    return (
      <li key={index} value={value} onClick={() => chooseItem(label)}>
        <a>{label}</a>
      </li>
    );
  };

  return (
    <div className={`dropdown ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
      <button className="dropdown-toggle" type="button" onClick={showDropdown}>
        {labelItem}
        <span className="caret"></span>
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          {list.map(renderDataDropDown)}
        </ul>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  list: PropTypes.array.isRequired
};

Dropdown.defaultProps = {
  list: []
};

export default Dropdown;
