import React, { useState } from 'react';
import axios from 'axios';
import './SignUp.css'; // Ensure to include your CSS file

const SignUp = ({ setAuthPopupVisible, toggleAuthType }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    const data = JSON.stringify({
      email: email,
      password: password,
      name: name // Assuming your backend also stores the name
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.syncpro.cloud/signup',
      headers: { 
        'Content-Type': 'application/json'
      },
      data: data
    };

    try {
      setLoading(true);
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      setSuccess(true);
      setLoading(false);
      setTimeout(() => {
        setAuthPopupVisible(false);
      }, 2000);
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        alert(`Sign Up failed: ${error.response.data.message || "An error occurred"}`);
      } else if (error.request) {
        // No response was received
        alert("No response from server. Please check your network connection.");
      } else {
        // Something happened setting up the request
        alert("An error occurred. Please try again.");
      }
      setLoading(false);
    }
    
  };

  return (
    <div>
      {loading && <div className="loader">Loading...</div>}
      {success && <div className="success_popup">Sign Up successful!</div>}
      {/* {!loading && !success && ( */}
        <>
          <h2>Sign Up</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button type="submit">Sign Up</button>
          </form>
          <div className="auth_toggle">
            <p>Already have an account? <span onClick={toggleAuthType}>Sign In</span></p>
          </div>
          <span className="auth_popup_close" onClick={() => setAuthPopupVisible(false)}>Skip</span>
        </>
    </div>
  );
};

export default SignUp;
