import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './Contributions.css';

const getStatusColor = (status) => {
  switch (status) {
    case "approved":
      return "green";
    case "Pending":
      return "#2e58a4";
    case "In Progress":
      return "orange";
    case "Failed":
      return "red";
    default:
      return "black";
  }
};

const capitalizeFLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
}

const ContributionCard = ({ contribution }) => (
  <div className="contribution_card">
    <div className="contribution_meta_info">
      <div className="contribution_meta_info_name">{contribution.project.title}</div>
      <div className="contribution_meta_info_description">{contribution.project.long_description}</div>
    </div>
    <div className="contribution_event_detail">
      <div
        className="contribution_event_detail_status"
        style={{ color: getStatusColor(contribution.current_status) }}
      >
        {capitalizeFLetter(contribution.current_status)}
      </div>
    </div>
  </div>
);

const Contributions = ({ userDetail }) => {
  const navigate = useNavigate();
  const [contributions, setContributions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchContributions = async () => {
      try {
        const response = await axios.get('https://api.syncpro.cloud/user-contribution-requests', {
          headers: {
            'Authorization': `Bearer ${userDetail.token}`
          },
          params: {
            page: currentPage,
            rowsPerPage: rowsPerPage
          }
        });
        setContributions(response.data.contribution_requests || []);
        setTotalPages(Math.ceil(response.data.totalCount / rowsPerPage));
      } catch (error) {
        console.error('Error fetching contributions:', error);
      }
    };

    fetchContributions();
  }, [userDetail.token, currentPage, rowsPerPage]);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  return (
    <div className="contributions_container">
      {contributions.map((contribution, index) => (
        <ContributionCard key={index} contribution={contribution} />
      ))}
      {/* <div className="pagination-controls">
        <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
          <option value={5}>5 rows per page</option>
          <option value={10}>10 rows per page</option>
          <option value={20}>20 rows per page</option>
        </select>
        <button 
          onClick={() => setCurrentPage(currentPage - 1)} 
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{currentPage} of {totalPages}</span>
        <button 
          onClick={() => setCurrentPage(currentPage + 1)} 
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div> */}
    </div>
  );
};

export default Contributions;
