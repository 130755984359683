import './CreateProjectSectionView.css';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CreateProjectSectionView = ({ userDetail }) => {
  const [stage, setStage] = useState(1);
  const [formData, setFormData] = useState({
    title: '',
    start_date: '',
    short_description: '',
    long_description: '',
    central_model: '',
    client_model: '',
    training_data_format: '',
    noise_values: '',
    current_status: '',
    host_user_email: userDetail.email,
    allowed_contributors: '',
    active_contributors: '',
    central_model_input_features: '',
    central_model_output_features: '',
    client_model_input_features: '',
    client_model_output_features: '',
    current_auc_after_convergence: '',
    target_auc_after_convergence: '',
    current_accuracy: '',
    target_accuracy: '',
    current_precision: '',
    target_precision: '',
    current_recall: '',
    target_recall: '',
    current_f1_score: '',
    target_f1_score: '',
    current_auc_roc: '',
    target_auc_roc: '',
    current_confusion_matrix: '',
    target_confusion_matrix: '',
    current_mean_absolute_error: '',
    target_mean_absolute_error: '',
    current_mean_squared_error: '',
    target_mean_squared_error: '',
    current_root_mean_squared_error: '',
    target_root_mean_squared_error: ''
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleIncrement = () => {
    if (stage < 12) {
      setStage(stage + 1);
    } else {
      handleSubmit();
    }
  };

  const handleDecrement = () => {
    if (stage > 1) {
      setStage(stage - 1);
    }

    if(stage==1){
        navigate('/');
    }
  };

  const handleStageClick = (stageNumber) => {
    setStage(stageNumber);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.syncpro.cloud/create-project',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userDetail.token}`
      },
      data: {
        ...formData,
        host_user_email: userDetail.email // Map host_user_email to email from userDetail
      }
    };
    
    setLoading(true);
    try {
      const response = await axios.request(config);
      console.log(response.data);
      setLoading(false);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000); // Hide success message after 3 seconds
      // Reset form data
      setFormData({
        title: '',
        start_date: '',
        short_description: '',
        long_description: '',
        central_model: '',
        client_model: '',
        training_data_format: '',
        noise_values: '',
        current_status: '',
        host_user_email: userDetail.email, // Reset with user email
        allowed_contributors: '',
        active_contributors: '',
        central_model_input_features: '',
        central_model_output_features: '',
        client_model_input_features: '',
        client_model_output_features: '',
        current_auc_after_convergence: '',
        target_auc_after_convergence: '',
        current_accuracy: '',
        target_accuracy: '',
        current_precision: '',
        target_precision: '',
        current_recall: '',
        target_recall: '',
        current_f1_score: '',
        target_f1_score: '',
        current_auc_roc: '',
        target_auc_roc: '',
        current_confusion_matrix: '',
        target_confusion_matrix: '',
        current_mean_absolute_error: '',
        target_mean_absolute_error: '',
        current_mean_squared_error: '',
        target_mean_squared_error: '',
        current_root_mean_squared_error: '',
        target_root_mean_squared_error: ''
      });
      setStage(1);
      // Redirect to homepage after 3 seconds
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const renderStageContent = () => {
    switch (stage) {
      case 1:
        return (
          <div className="section Project_details">
            <h2>Stage 1: Specify project details</h2>
            <div className="field">
              <label>Project Title</label>
              <input type="text" name="title" value={formData.title} onChange={handleChange} placeholder="Enter Project title" />
            </div>
            <div className="field">
              <label>Start Date</label>
              <input type="date" name="start_date" value={formData.start_date} onChange={handleChange} />
            </div>
            <div className="field">
              <label>Short Description</label>
              <textarea name="short_description" value={formData.short_description} onChange={handleChange} placeholder="Enter short description"></textarea>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="section Project_details">
            <h2>Stage 2: Specify project details</h2>
            <div className="field">
              <label>Long Description</label>
              <textarea name="long_description" value={formData.long_description} onChange={handleChange} placeholder="Enter long description"></textarea>
            </div>
            <div className="field">
              <label>Central Model</label>
              <textarea name="central_model" value={formData.central_model} onChange={handleChange} placeholder="Enter details about the central model"></textarea>
            </div>
            <div className="field">
              <label>Client Model</label>
              <textarea name="client_model" value={formData.client_model} onChange={handleChange} placeholder="Enter details about the client model"></textarea>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="section Project_details">
            <h2>Stage 3: Add existing resources</h2>
            <div className="field">
              <label>Training Data Format</label>
              <textarea name="training_data_format" value={formData.training_data_format} onChange={handleChange} placeholder="Enter training data format"></textarea>
            </div>
            <div className="field">
              <label>Noise Values</label>
              <textarea name="noise_values" value={formData.noise_values} onChange={handleChange} placeholder="Enter noise values"></textarea>
            </div>
            <div className="field">
              <label>Current Status</label>
              <textarea name="current_status" value={formData.current_status} onChange={handleChange} placeholder="Enter current status"></textarea>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="section Project_details">
            <h2>Stage 4: Add existing resources</h2>
            <div className="field">
              <label>Host User Email</label>
              <input type="email" name="host_user_email" value={userDetail.email} disabled />
            </div>
            <div className="field">
              <label>Allowed Contributors</label>
              <textarea name="allowed_contributors" value={formData.allowed_contributors} onChange={handleChange} placeholder="Enter allowed contributors"></textarea>
            </div>
            <div className="field">
              <label>Active Contributors</label>
              <textarea name="active_contributors" value={formData.active_contributors} onChange={handleChange} placeholder="Enter active contributors"></textarea>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="section Project_details">
            <h2>Stage 5: Review and create</h2>
            <div className="field">
              <label>Central Model Input Features</label>
              <textarea name="central_model_input_features" value={formData.central_model_input_features} onChange={handleChange} placeholder="Enter central model input features"></textarea>
            </div>
            <div className="field">
              <label>Central Model Output Features</label>
              <textarea name="central_model_output_features" value={formData.central_model_output_features} onChange={handleChange} placeholder="Enter central model output features"></textarea>
            </div>
            <div className="field">
              <label>Client Model Input Features</label>
              <textarea name="client_model_input_features" value={formData.client_model_input_features} onChange={handleChange} placeholder="Enter client model input features"></textarea>
            </div>
          </div>
        );
      case 6:
        return (
          <div className="section Project_details">
            <h2>Stage 6: Review and create</h2>
            <div className="field">
              <label>Client Model Output Features</label>
              <textarea name="client_model_output_features" value={formData.client_model_output_features} onChange={handleChange} placeholder="Enter client model output features"></textarea>
            </div>
            <div className="field">
              <label>Current AUC after Convergence</label>
              <input type="number" name="current_auc_after_convergence" value={formData.current_auc_after_convergence} onChange={handleChange} placeholder="Enter current AUC after convergence" />
            </div>
            <div className="field">
              <label>Target AUC after Convergence</label>
              <input type="number" name="target_auc_after_convergence" value={formData.target_auc_after_convergence} onChange={handleChange} placeholder="Enter target AUC after convergence" />
            </div>
          </div>
        );
      case 7:
        return (
          <div className="section Project_details">
            <h2>Stage 7: Review and create</h2>
            <div className="field">
              <label>Current Accuracy</label>
              <input type="number" name="current_accuracy" value={formData.current_accuracy} onChange={handleChange} placeholder="Enter current accuracy" />
            </div>
            <div className="field">
              <label>Target Accuracy</label>
              <input type="number" name="target_accuracy" value={formData.target_accuracy} onChange={handleChange} placeholder="Enter target accuracy" />
            </div>
            <div className="field">
              <label>Current Precision</label>
              <input type="number" name="current_precision" value={formData.current_precision} onChange={handleChange} placeholder="Enter current precision" />
            </div>
          </div>
        );
      case 8:
        return (
          <div className="section Project_details">
            <h2>Stage 8: Review and create</h2>
            <div className="field">
              <label>Target Precision</label>
              <input type="number" name="target_precision" value={formData.target_precision} onChange={handleChange} placeholder="Enter target precision" />
            </div>
            <div className="field">
              <label>Current Recall</label>
              <input type="number" name="current_recall" value={formData.current_recall} onChange={handleChange} placeholder="Enter current recall" />
            </div>
            <div className="field">
              <label>Target Recall</label>
              <input type="number" name="target_recall" value={formData.target_recall} onChange={handleChange} placeholder="Enter target recall" />
            </div>
          </div>
        );
      case 9:
        return (
          <div className="section Project_details">
            <h2>Stage 9: Review and create</h2>
            <div className="field">
              <label>Current F1 Score</label>
              <input type="number" name="current_f1_score" value={formData.current_f1_score} onChange={handleChange} placeholder="Enter current F1 score" />
            </div>
            <div className="field">
              <label>Target F1 Score</label>
              <input type="number" name="target_f1_score" value={formData.target_f1_score} onChange={handleChange} placeholder="Enter target F1 score" />
            </div>
            <div className="field">
              <label>Current AUC ROC</label>
              <input type="number" name="current_auc_roc" value={formData.current_auc_roc} onChange={handleChange} placeholder="Enter current AUC ROC" />
            </div>
          </div>
        );
      case 10:
        return (
          <div className="section Project_details">
            <h2>Stage 10: Review and create</h2>
            <div className="field">
              <label>Target AUC ROC</label>
              <input type="number" name="target_auc_roc" value={formData.target_auc_roc} onChange={handleChange} placeholder="Enter target AUC ROC" />
            </div>
            <div className="field">
              <label>Current Confusion Matrix</label>
              <textarea name="current_confusion_matrix" value={formData.current_confusion_matrix} onChange={handleChange} placeholder="Enter current confusion matrix"></textarea>
            </div>
            <div className="field">
              <label>Target Confusion Matrix</label>
              <textarea name="target_confusion_matrix" value={formData.target_confusion_matrix} onChange={handleChange} placeholder="Enter target confusion matrix"></textarea>
            </div>
          </div>
        );
      case 11:
        return (
          <div className="section Project_details">
            <h2>Stage 11: Review and create</h2>
            <div className="field">
              <label>Current Mean Absolute Error</label>
              <input type="number" name="current_mean_absolute_error" value={formData.current_mean_absolute_error} onChange={handleChange} placeholder="Enter current mean absolute error" />
            </div>
            <div className="field">
              <label>Target Mean Absolute Error</label>
              <input type="number" name="target_mean_absolute_error" value={formData.target_mean_absolute_error} onChange={handleChange} placeholder="Enter target mean absolute error" />
            </div>
            <div className="field">
              <label>Current Mean Squared Error</label>
              <input type="number" name="current_mean_squared_error" value={formData.current_mean_squared_error} onChange={handleChange} placeholder="Enter current mean squared error" />
            </div>
          </div>
        );
      case 12:
        return (
          <div className="section Project_details">
            <h2>Stage 12: Review and create</h2>
            <div className="field">
              <label>Target Mean Squared Error</label>
              <input type="number" name="target_mean_squared_error" value={formData.target_mean_squared_error} onChange={handleChange} placeholder="Enter target mean squared error" />
            </div>
            <div className="field">
              <label>Current Root Mean Squared Error</label>
              <input type="number" name="current_root_mean_squared_error" value={formData.current_root_mean_squared_error} onChange={handleChange} placeholder="Enter current root mean squared error" />
            </div>
            <div className="field">
              <label>Target Root Mean Squared Error</label>
              <input type="number" name="target_root_mean_squared_error" value={formData.target_root_mean_squared_error} onChange={handleChange} placeholder="Enter target root mean squared error" />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='create_project_container_sidenav_wrapper'>
      {loading && <div className="loader">Loading...</div>}
      {success && <div className="success-popup">Project successfully created!</div>}
      <div className='create_project_container_sidenav'>
        {Array.from({ length: 12 }, (_, idx) => ({ stage: idx + 1, name: `Stage ${idx + 1}` })).map((item, idx) => {
          return (
            <div
              key={idx}
              className='create_project_container_sidenav_stage'
              style={{ background: `${stage === item.stage ? '#006fff' : 'lightgray'}`, color: `${stage === item.stage ? '#fff' : '#000'}` }}
              onClick={() => handleStageClick(item.stage)}
            >
              <div className='create_project_container_sidenav_stage_name'>{`Stage - ${item.stage}`}</div>
              <div className='create_project_container_sidenav_stage_detail'>{item.name}</div>
            </div>
          )
        })}
      </div>
      <div className="create_project_container">
        <div className="create_project_header">
          <h1> Project details</h1>
        </div>
        <div className="create_project_content">
          {renderStageContent()}
        </div>
        <div className="create_project_footer">
          <button onClick={handleDecrement} >{stage == 1 ? 'Cancel' : 'Back'} </button>
          <button onClick={handleSubmit} disabled={stage !== 12}>{stage > 11 ? 'Submit' : 'Skip'}</button>
          <button onClick={handleIncrement} disabled={stage === 12}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default CreateProjectSectionView;
