import './RecentContributionsSectionView.css';


const RecentContributionsSectionView = () =>{
  return (
<div class="recently_contributed_container">
    <div class="recently_contributed_header">
      <div class="recently_contributed_title">Recently contributed </div>
      <div class="menu">⋮</div>
    </div>
    <div class="recently_contributed_list">
      <div class="recently_contributed_item">
        <div class="recently_contributed_icon iam"></div>
        <div class="recently_contributed_text">Network Optimization</div>
      </div>
      <div class="recently_contributed_item">
        <div class="recently_contributed_icon s3"></div>
        <div class="recently_contributed_text">Smart Cities</div>
      </div>
      <div class="recently_contributed_item">
        <div class="recently_contributed_icon ec2"></div>
        <div class="recently_contributed_text">Personalized Medicine</div>
      </div>
    </div>
    <div class="recently_contributed_footer">
      <a href="#">View all Contributions</a>
    </div>
  </div>
  );
}

export default RecentContributionsSectionView;
