import React from "react";
import "./ProjectDetail.css"; // You'll need to create this CSS file to style the component
import { useLocation } from 'react-router-dom';

const ProjectDetail = () => {
    const location = useLocation();
    const { project } = location.state || {}; 
    console.log(project,"project-----")
  return (
    <div className="project-detail-container">
      <header className="project-header">
        <div className="project-title">
          <h1>{project.title}</h1>
          <span className="project-subtitle">{project.current_status}</span>
        </div>
        <div className="project-actions">
          <button className="button">Contribute</button>
        </div>
      </header>
      
      <section className="project-access">
        <p>
            {project.short_description}
        </p>
        {/* <button className="submit-button">Create Contribution Request</button> */}
      </section>
      
      <section className="model-details">
        <h2>Model Details</h2>
        <div className="model-information">
          <h3>Model Information</h3>
          <p>
            {project.long_description}
          </p>
          <p>
            <strong>Model developer:</strong> Meta
          </p>
          <p>
            <strong>Model Architecture:</strong> Llama 3.1 is an auto-regressive
            language model that uses an optimized transformer architecture. The
            tuned versions use supervised fine-tuning (SFT) and reinforcement
            learning with human feedback (RLHF) to align with human preferences
            for helpfulness and safety.
          </p>
        </div>
        
        <div className="model-stats">
          <h3>Downloads</h3>
          <p>1408</p>
          <p>738 in the last 30 days</p>
          
          <h3>Tags</h3>
          <ul>
            <li>Text Generation</li>
            <li>Text</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default ProjectDetail;
