import React, { useEffect, useState } from 'react';
import './ContributionRequestsSectionView.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ContributionRequestsSectionView = ({ userDetail }) => {
  const [contributionRequests, setContributionRequests] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContributionRequests = async () => {
      try {
        const token = localStorage.getItem('access_token'); // Assuming you store the token in localStorage
        const response = await axios.get('https://api.syncpro.cloud/user-contribution-requests', {
          headers: {
            'Authorization': `Bearer ${userDetail.token}`
          }
        });
        setContributionRequests(response.data.contribution_requests);
      } catch (error) {
        console.error('Error fetching contribution requests:', error);
      }
    };

    fetchContributionRequests();
  }, []);

  const getStatusClassName = (status) => {
    return status === 'Active' ? 'recently_contributed_status active' : 'recently_contributed_status';
  };

  return (
    <div className="recently_contributed_container">
      <div className="recently_contributed_header">
        <div className="recently_contributed_title">Contribution Requests</div>
        <div className="menu">⋮</div>
      </div>
      <div className="recently_contributed_list">
        {contributionRequests.map((request) => (
          <div className="recently_contributed_item" key={request._id}>
            <div className="recently_contributed_text">{request.project.title}</div>
            <div className={getStatusClassName(request.project.current_status)}>
              {request.project.current_status}
            </div>
          </div>
        ))}
      </div>
      <div className="recently_contributed_footer">
        <a href="#">View all Requests</a>
      </div>
    </div>
  );
};

export default ContributionRequestsSectionView;
