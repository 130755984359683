import React, { useState, useEffect } from "react";
import './ProjectListing.css';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import axios from "axios";
import ProjectCard from "../../components/ProjectCard/ProjectCard";

const ProjectListing = ({ userDetail }) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://api.syncpro.cloud/projects`,
      };

      try {
        const response = await axios.request(config);
        setProjects(response.data.projects);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProjects();
  }, []);

  const handleContribute = (project) => {
    navigate('/create-contribution-request', { state: { project } });
  };

  return (
    <div>
      <div className="homepage_main_container_listing">
        <div className="homepage_main_master_text2">
          Currently Ongoing Projects
        </div>
        {/* <div className="homepage_main_container_location_search">
          <div className="homepage_main_container_location">
            <input
              placeholder="Location"
              className="homepage_main_container_location_input"
            />
          </div>
          <div className="homepage_main_container_category">
            <input
              placeholder="Categories"
              className="homepage_main_container_category_input"
            />
          </div>
          <div className="homepage_main_container_search">
            <input
              placeholder="Search"
              className="homepage_main_container_search_input"
            />
          </div>
        </div> */}
      </div>
      <div className="homepage_main_container_project_listing">
        {projects.map((item, idx) => (
          <ProjectCard key={idx} item={item} handleContribute={handleContribute} userDetail={userDetail} />
        ))}
      </div>
    </div>
  );
};

export default ProjectListing;
